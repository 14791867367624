export default function Item({text, color, backgroundColor, onClick}) {
    // Calculate the font size based on the text length
    const baseSize = 15; // Base font size in vw for 5 characters
    const fontSize = `${Math.min(baseSize / (text.length / 5), baseSize)}vw`;

    return (
        <div 
            className="item" 
                style={{
                    color:color, 
                    backgroundColor:backgroundColor,
                    fontSize: fontSize
                }}
                onClick={onClick}>
            {text}
        </div>
    )
}
