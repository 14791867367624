import { useState } from "react";
import { Link, useResolvedPath, useMatch } from "react-router-dom";

export default function Navbar({ links }) {
    const [isCollapsed, setIsCollapsed] = useState(true);

    const toggleNavbar = () => {
        setIsCollapsed(!isCollapsed);
    };

    const closeNavbar = () => {
        setIsCollapsed(true);
    };

    return (
        <div className="container-fluid">
            <nav className="navbar navbar-dark bg-primary mb-4">
                <div className="container-lg">
                    <div className="navbar-brand">Sight Words</div>
                    <button
                        className="navbar-toggler"
                        type="button"
                        aria-controls="navbarCollapse"
                        aria-expanded={!isCollapsed}
                        aria-label="Toggle navigation"
                        onClick={toggleNavbar}
                    >
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className={`collapse navbar-collapse ${isCollapsed ? '' : 'show'}`} id="navbarCollapse">
                        <ul className="navbar-nav ms-auto mb-2 mb-md-0">
                            {links.map((link, index) => (
                                <li className="nav-item" key={index}>
                                    <CustomLink className="nav-link" to={"/" + link.name} onClick={closeNavbar}>
                                        {link.title}
                                    </CustomLink>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </nav>
        </div>
    );
}

function CustomLink({ to, className, children, onClick, ...props }) {
    const resolvedPath = useResolvedPath(to);
    const isActive = useMatch({ path: resolvedPath.pathname, end: true });

    const newClassName = className + (isActive ? " active" : "");
    return (
        <Link to={to} className={newClassName} {...props} onClick={onClick}>
            {children}
        </Link>
    );
}
